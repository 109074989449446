window.ConcurrentEditingWarning = function(url, csrfToken) {
  if (!url) throw new Error('You must provide a URL to send the beacon to.');

  // if we want this to be more flexible we should extract this functionality so its called everytime event listner fires
  // we could also put this in forms.js since that would cover all the hybrid forms, and one could argue that
  // concurrent editing is not an issue for SPA views because they save right away
  const pathSegments = window.location.pathname.split('/'),
        isPageTypeForm = pathSegments.includes('plans') || pathSegments.includes('reports');

  if (!isPageTypeForm) return; // don't set up listener if it's not a form page

  // NOTE!
  // We are listening to beforeunload instead of visibilitychange because we don't want to deal with tabs losing focus
  // and the backend thinking the user is not on the page when they still are.
  // This meas we DO NOT SUPPORT MOBILE DEVICES for the edge case described here: https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#usage_notes
  window.addEventListener('beforeunload', () => {
    const formData = new FormData();

    formData.append('path', window.location.pathname);
    formData.append('csrfmiddlewaretoken', csrfToken);

    navigator.sendBeacon(url, formData);
  });
};
